import React, { useState } from "react";
import "./App.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleFileUpload = async () => {
    setIsLoading(true); // Start loading
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("https://alefredoexams.com:8443/convert", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "converted_file.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      toast.success("File downloaded successfully.");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="flex items-center justify-center mb-6">
            <img src="/logo.svg" alt="Logo" className="shrink-0 w-[29px]" />
            <div className="ml-1">
              <span className="font-semibold text-teal-600">Alefredo</span>
              <span className="block text-teal-600 mt-[-2px]">Exams</span>
            </div>
          </div>

          <div className="max-w-xl mx-auto">
            <label
              className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <span className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
                <span className="font-medium text-gray-600">
                  Drop files to Attach, or
                  <span className="text-blue-600 underline ml-1">browse</span>
                </span>
              </span>
              <input type="file" className="hidden" />
            </label>

            {file && (
              <button
                onClick={handleFileUpload}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                disabled={isLoading}
              >
                {isLoading ? "Uploading..." : "Upload and Convert"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
